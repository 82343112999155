import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'

import { Box, Button, CardMedia, MenuItem, TextField } from '@mui/material'

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import axios from 'axios';

import { useLocation } from 'react-router-dom';
import NavBarSearch from './NavBarSearch';
import PopupSpecialEvents from './PopupSpecialEvents';

import IconButton from '@mui/material/IconButton';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';


import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import HoverCard from "../components/HoverCard";

const SearchPage = () => {

    const [swiper, setSwiper] = useState(null);
    const isMobile = () => {
        return window.innerWidth <= 768;
    };
    const goToPrevSlide = () => {
        if (swiper) {
        swiper.slidePrev();
        }
    };

    const goToNextSlide = () => {
        if (swiper) {
        swiper.slideNext();
        }
    };

    const [eventData, setEventData] = useState(null);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    const location = useLocation();

    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleClickOpen = (event) => {
        setSelectedEvent(event);
    };
    const [showArrows, setShowArrows] = useState(true);

    const handleClose = () => {
        setSelectedEvent(null);
    };

    useEffect(() => {
        const eventsUrl = 'https://night-out-d92dbe5b8513.herokuapp.com/api/event';
        axios.get(eventsUrl)
            .then(response => {
                setEventData(response.data);
            })
            .catch(error => {
                console.log('Error fetching data:', error);
                setError(error)
            });
    }, []);

    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const handleSlideChange = (swiper) => {
        setIsStart(swiper.isBeginning)
        setIsEnd(swiper.isEnd)
    };



    const filterEvents = (name, city, category, date) => {
        if (!name && !city && !category && !date) {
            return eventData;
        }

        return eventData?.filter((event) =>
            (name ? event.objectName.toLowerCase().includes(name.toLowerCase()) : true) &&
            (city ? event.city === city : true) &&
            (category ? event.category === category : true) &&
            (date ? dayjs(event.date).isSame(date, 'day') : true)
        );
    };

    const filteredEvents = filterEvents(searchTerm, selectedCity, selectedCategory, selectedDate);

    const [cardsPerPage, setCardsPerPage] = useState(3);

    const updateCardsPerPage = () => {
        if (window.innerWidth < 600) {
            setCardsPerPage(2);
        } else {
            setCardsPerPage(3);
        }
    };

    useEffect(() => {
        updateCardsPerPage();
        window.addEventListener('resize', updateCardsPerPage);

        return () => {
            window.removeEventListener('resize', updateCardsPerPage);
        };
    }, []);
    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    useEffect(() => {
        if (swiper && swiper.slides) {
            const slideCount = swiper.slides.length;
            if(isMobile()){
                setShowArrows(slideCount > 2)
            }else{
                setShowArrows(slideCount > 4)
            }
        }
    }, [swiper, filteredEvents]);


    if (error) {
        return <div>Error: {error}</div>;
    }
    
    return (
        <Box sx={{ background: '#121212', minHeight: '100vh' }}>
            <NavBarSearch searchPage={location.pathname} />
            <Box sx={{ padding: cardsPerPage === 2 ? '20px' : '48px', paddingTop: cardsPerPage === 2 ? '30px' : '200px' }}>
                <Box sx={{ borderRadius: '10px', width: cardsPerPage === 2 ? '80%' : '60%', background: 'white', margin: '40px auto', padding: '20px' }}>
                    <Box sx={{ display: 'flex', flexWrap: cardsPerPage === 2 && 'wrap', alignItems: 'center', justifyContent: 'space-between', gap: '15px' }}>
                        <TextField
                            label="Име на објект"
                            variant="outlined"
                            sx={{ width: '100%' }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Избери град"
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.target.value)}
                            sx={{ width: '100%' }}
                        >
                            <MenuItem value="">None</MenuItem>
                            {eventData
                                ? [...new Set(eventData.map(city => city.city))].map((city) => (
                                    <MenuItem key={city} value={city}>
                                        {city}
                                    </MenuItem>
                                ))
                                : null
                            }
                        </TextField>
                        <TextField
                            id="outlined-select-category"
                            select
                            label="Избери тип на објект"
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            sx={{ width: '100%' }}
                        >
                            <MenuItem value="">None</MenuItem>
                            {eventData
                                ? [...new Set(eventData.map(category => category.category))].map((category) => (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                ))
                                : null
                            }
                        </TextField>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Датум"
                                onChange={(newValue) => setSelectedDate(newValue)}
                                sx={{ width: '100%' }}
                            />
                        </LocalizationProvider>
                        {selectedDate && <Button sx={{ margin: '0 auto' }} variant='outlined' onClick={() => setSelectedDate('')}>Clear</Button>}
                    </Box>
                </Box>
                <Swiper onSlideChange={handleSlideChange} onSwiper={setSwiper} ref={swiper} slidesPerView={1} spaceBetween={"20"} freeMode={false}
                            breakpoints={{
                                320: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 30,
                                },
                              }}>
                        {filteredEvents && filteredEvents?.map((eventData, index) => (
                            <SwiperSlide key={index}>
                                <HoverCard event={eventData} />

                            </SwiperSlide>
                        ))}
                    </Swiper>
            </Box>
            {swiper && showArrows &&
                <Box sx={{ marginTop: '16px' ,textAlign: 'center',}} >
                    <IconButton onClick={goToPrevSlide} disabled={isStart}>
                        <ArrowCircleLeftIcon
                            sx={{ fontSize: '2.5rem', color: isStart ? 'grey' : 'white' }}
                        />
                    </IconButton>
                    <IconButton onClick={goToNextSlide} disabled={isEnd}>
                        <ArrowCircleRightIcon
                            sx={{ fontSize: '2.5rem', color: isEnd ? 'grey' : 'white' }}
                        />
                    </IconButton>
                </Box>
            }

            {selectedEvent && (
                <PopupSpecialEvents open={true} handleClose={handleClose} event={selectedEvent} cardsPerPage={cardsPerPage} />
            )}
        </Box >
    )
}

export default SearchPage