import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import '../styles/Navbar.css'
import Logo from './../379638555_628159602842181_1664427405343005593_n.png'

import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const NavBar = ({ searchPage }) => {

    const pages = ['Home', 'Our Clients',];

    const [isScrolled, setIsScrolled] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);

    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <AppBar style={{ background: isScrolled ? 'black' : 'transparent', transition: 'background-color 0.3s ease-in-out' }} position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <Box sx={{ width: '50px', cursor: 'pointer' }} onClick={() => navigate('/')}>
                        <img width={'100%'} src={Logo} alt="" />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'end' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} >
                                    <Button
                                        href={page === 'Our Clients' ? '#ourClients' : undefined}
                                        key={page}
                                        onClick={() => {
                                            if (page === 'Home') {
                                                navigate('/');
                                            } else {
                                                handleCloseNavMenu();
                                            }
                                        }}
                                        sx={{ display: 'block', textTransform: 'none', color: 'black', textAlign: 'start' }}
                                    >
                                        {page}
                                    </Button>
                                </MenuItem>

                            ))}
                            {searchPage !== '/search' && <Button onClick={() => navigate('/search')} sx={{ marginLeft: '10px', width: '80%', borderRadius: '40px', textTransform: 'none', }} variant='contained'>Search</Button>}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end', }}>
                        {pages.map((page) => (
                            <Button
                                href={page === 'Our Clients' ? '#ourClients' : undefined}
                                key={page}
                                onClick={() => {
                                    if (page === 'Home') {
                                        navigate('/');
                                    } else {
                                        handleCloseNavMenu();
                                    }
                                }}
                                sx={{ my: 2, display: 'block', textTransform: 'none', color: 'white' }}
                            >
                                {page}
                            </Button>
                        ))}
                        {searchPage !== '/search' &&
                            <Button onClick={() => navigate('/search')} sx={{ marginLeft: '10px', borderRadius: '40px', textTransform: 'none', width: '100px', height: '35px', alignSelf: 'center' }} variant='contained'>Search </Button>}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default NavBar