import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { styled } from '@mui/system';
import { SocialIcon } from 'react-social-icons'

import Logo from './../379638555_628159602842181_1664427405343005593_n.png'
import EmailIcon from '@mui/icons-material/Email';

const Footer = styled('footer')({
  backgroundColor: 'black',
  boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
  padding: '16px',
  marginTop: 'auto',
  color: 'white',
  justifyContent: 'center',
});

const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const LogoImage = styled('img')({
  width: '30px',
  marginRight: '8px',
});

const AppFooter = () => {
  return (
      <Footer>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <LogoContainer>
            <a href="/">
              <LogoImage
                  alt="logo"
                  src={Logo}
                  width="30px"
              />
            </a>
            <Typography variant="body2">
              &copy; NightOut, 2023. All rights reserved.
            </Typography>
          </LogoContainer>
          <Box flexDirection={"column"} display={"flex"} gap={"16px"}>

            <Box display={"flex"} gap={"16px"} justifyContent={"flex-start"}>
              <SocialIcon
                  style={{ width: '30px', height: '30px' }}
                  href="#"
                  url="www.facebook.com"
              />
              <SocialIcon
                  style={{ width: '30px', height: '30px' }}
                  href="#"
                  url="www.instagram.com"
              />
            </Box>
          </Box>

        </Box>
      </Footer>
  );
};

export default AppFooter;