import React, { useEffect, useState, useRef } from 'react'

import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import axios from 'axios';

import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from 'swiper/react';

const OurClients = () => {

    const [swiper, setSwiper] = useState(null);
    const isMobile = () => {
        return window.innerWidth <= 768;
    };
    const goToPrevSlide = () => {
        if (swiper) {
        swiper.slidePrev();
        }
    };

    const goToNextSlide = () => {
        if (swiper) {
        swiper.slideNext();
        }
    };

    const [clientData, setClientsData] = useState(null);
    const [error, setError] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const [cardsPerPage, setCardsPerPage] = useState(3);



    const startIndex = currentPage * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const currentCards = clientData?.slice(startIndex, endIndex);

    const updateCardsPerPage = () => {
        if (window.innerWidth < 768) {
            setCardsPerPage(2);
        } else if (window.innerWidth > 1800) {
            setCardsPerPage(4);
        } else {
            setCardsPerPage(3);
        }
    };

    useEffect(() => {
        updateCardsPerPage();
        window.addEventListener('resize', updateCardsPerPage);

        return () => {
            window.removeEventListener('resize', updateCardsPerPage);
        };
    }, []);

        useEffect(() => {
        updateCardsPerPage();
        window.addEventListener('resize', updateCardsPerPage);

        return () => {
            window.removeEventListener('resize', updateCardsPerPage);
        };
    }, []);



    useEffect(() => {
        const clientUrl = 'https://night-out-d92dbe5b8513.herokuapp.com/api/client';
        axios.get(clientUrl)
            .then(response => {
                setClientsData(response.data);
            })
            .catch(error => {
                console.log('Error fetching data:', error);
                setError(error)
            });
    }, []);


    const [showArrows, setShowArrows] = useState(true);

    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const handleSlideChange = (swiper) => {
        setIsStart(swiper.isBeginning)
        setIsEnd(swiper.isEnd)
    };


    useEffect(() => {
        if (swiper && swiper.slides) {
            const slideCount = swiper.slides.length;
            if(isMobile()){
                setShowArrows(slideCount > 2)
            }else{
                setShowArrows(slideCount > 3)
            }
        }
    }, [swiper, clientData]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const wrapperCards = {
        display: 'flex', flexBasis: '50%', gap: '20px', justifyContent: cardsPerPage === 2 && 'center', width:"50%",
        '@media (max-width: 768px)': { 
          width: '100%',
        },
      }
      const divWrapper = { marginTop: '20px', display: cardsPerPage === 2 ? 'block' : 'flex', alignItems: 'center', justifyContent: "space-between" ,
      '@media (max-width: 768px)': { 
        flexDirection: 'column',
      },
    }



    return (
        <Box sx={{background: 'white',}}>
            <Box sx={{ padding: cardsPerPage === 2 ? '20px' : '48px',  maxWidth: '1280px', margin: 'auto' }} id='ourClients'>
                {cardsPerPage === 2 ? <Typography textAlign={'center'} mb={"48px"} variant='h5'>Our Clients</Typography> : <Typography textAlign={'center'} mb={"48px"} variant='h3'>Our Clients</Typography>}
                <Box sx={divWrapper}>
                    <Box sx={{ marginRight: (cardsPerPage === 3 || cardsPerPage === 4) && '25px' }}>
                        {cardsPerPage === 2 ?
                            <Typography sx={{ textAlign: 'center', marginBottom: '15px' }} variant='h6'>Unlock your organization’s potential</Typography> :
                            <Typography variant='h4' sx={{ textAlign: 'center' }}>Unlock your organization’s potential</Typography>
                        }
                        {showArrows &&
                            <Box sx={{ textAlign: 'center', marginTop: cardsPerPage === 3 && '20px' }} >
                                <IconButton onClick={goToPrevSlide} disabled={isStart}>
                                    <ArrowCircleLeftIcon
                                        sx={{ fontSize: '2.5rem', color: isStart ? "grey" : 'black' }}
                                    />
                                </IconButton>
                                <IconButton onClick={goToNextSlide} disabled={isEnd}>
                                    <ArrowCircleRightIcon
                                        sx={{ fontSize: '2.5rem', color: isEnd ? "grey" : 'black' }}
                                    />
                                </IconButton>
                            </Box>
                        }

                    </Box>
                    <Box sx={wrapperCards}>
                    <Swiper onSlideChange={handleSlideChange} className={"swiper-container_custom"} observer={true}  onSwiper={setSwiper} ref={swiper} slidesPerView={1} spaceBetween={"20"} freeMode={false}
                            breakpoints={{
                                320: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 3,
                                  spaceBetween: 30,
                                },
                              }}>
                        {clientData && clientData.map((card, index) => (
                            <SwiperSlide key={index}>
                                <Card sx={{ border: '2px solid black', boxSizing: 'border-box', height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    image={card.url}
                                    sx={{
                                        objectFit: 'cover',
                                        borderBottom: '1px solid black',
                                    }}
                                    />
                                    <CardContent sx={{ textAlign: 'center', display: 'flex',flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography
                                        sx={{
                                            verticalAlign: 'middle',
                                            fontWeight: 'bold',
                                            height: 'auto'
                                        }}
                                    >
                                        {card.name}
                                    </Typography>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default OurClients