
import './App.css';
import { Routes, Route } from "react-router-dom"

import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';

import './styles/Slider.css'
function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
      </Routes>


    </div>
  );
}

export default App;
