import React from 'react'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: '30px',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const PopupSpecialEvents = ({ handleClose, open, event, cardsPerPage }) => {
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                style={{ padding: '24px' }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ textAlign: 'center' }}>
                    <Typography gutterBottom sx={{ fontSize: '1.5rem', fontWeight: 'bold' }} >
                        {event.objectName}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: cardsPerPage === 2 ? '0.6rem' : '0.8rem', fontWeight: 'bold' }} >
                        Изведувач: {event.djName}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: cardsPerPage === 2 ? '0.6rem' : '0.8rem', fontWeight: 'bold' }}>
                        Цена: {event.price}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: cardsPerPage === 2 ? '0.6rem' : '0.8rem', fontWeight: 'bold' }}>
                        Датум: {event.day}, {event.date}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: cardsPerPage === 2 ? '0.6rem' : '0.8rem', fontWeight: 'bold' }}>
                        Музика: {event.music}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: cardsPerPage === 2 ? '0.6rem' : '0.8rem', fontWeight: 'bold' }}>
                        Локација: {event.location}
                    </Typography>
                    <Typography gutterBottom sx={{ fontSize: cardsPerPage === 2 ? '0.6rem' : '0.8rem', fontWeight: 'bold' }}>
                        Тел. Број: {event.phoneNumber}
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

export default PopupSpecialEvents