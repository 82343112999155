import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Button, Slide, Typography } from '@mui/material';
import Box from "@mui/material/Box";

function HoverCard({ event }) {
    const [hovered, setHovered] = useState(false);
    const isMobile = () => {
        return window.innerWidth <= 768;
    };
    const handleMouseEnter = () => {
        if (!isMobile()) {
            setHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isMobile()) {
            setHovered(false);
        }
    };
    const handleMouseClick = () => {
        if (isMobile()) {
            setHovered(!hovered);
        }
    };

    const overlay={
        position: 'absolute',
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            background: 'rgba(0, 0, 0, 0.8)',
            padding: '16px',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
    }
    const typoClassHeading = {
        fontSize: '22px',
        fontWeight: 'bold',
        '@media (max-width: 768px)': {
            fontSize: '14px',
        },
    }
    const typoClass = {
        fontSize: '16px',
        fontWeight: 'bold',
        '@media (max-width: 768px)': {
            fontSize: '12px',
        },
    }
    return (
        <Card
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleMouseClick}
            sx={{
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <CardMedia component="img" alt="green iguana" image={event.url} />
            <Slide in={hovered} direction="left" timeout={500} mountOnEnter unmountOnExit>
                <Box sx={overlay} textAlign={'center'} color={'white'}>
                    <Typography gutterBottom sx={typoClassHeading} >
                        {event.objectName}
                    </Typography>
                    <Typography gutterBottom sx={typoClass} >
                        Изведувач: {event.djName}
                    </Typography>
                    <Typography gutterBottom sx={typoClass}>
                        Цена: {event.price}
                    </Typography>
                    <Typography gutterBottom sx={typoClass}>
                        Датум: {event.day}, {event.date}
                    </Typography>
                    <Typography gutterBottom sx={typoClass}>
                        Музика: {event.music}
                    </Typography>
                    <Typography gutterBottom sx={typoClass}>
                        Локација: {event.location}
                    </Typography>
                    <Typography gutterBottom sx={typoClass}>
                        Тел. Број: {event.phoneNumber}
                    </Typography>
                </Box>
            </Slide>
        </Card>
    );
}

export default HoverCard;
