import React from 'react'

import LandingPage from './LandingPage'
import SpecialEvents from './SpecialEvents'
import OurClients from './OurClients'
import Footer from './Footer'

const HomePage = () => {

    return (
        <>
            <LandingPage />
            <SpecialEvents />
            <OurClients />
            <Footer />
        </>
    )
}

export default HomePage