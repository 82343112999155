import React, { useState, useEffect } from 'react'

import NavBar from './NavBar'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import video from './../47a8c529-f34d1a26.mp4';

const LandingPage = () => {

    const videoBackground = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '-1',
    }

    const videoWrapper = {
        height: '100vh',
        '@media (max-width: 768px)': { 
          height: '75vh',
        },
      }

    const textWrapper = {
    textAlign: 'center',
    '@media (max-width: 768px)': { 
        textAlign: 'left',
    },
    }


    const [width, setWidth] = useState('');

    const videoStyle = {
        objectFit: 'cover',
        width: width,
    }


    useEffect(() => {
        videoWidth();
        window.addEventListener('resize', videoWidth);

        return () => {
            window.removeEventListener('resize', videoWidth);
        };
    }, []);


    const videoWidth = () => {
        if (window.innerWidth > 1200) {
            setWidth('100%');
        }
    }

    return (
        <Box sx={videoWrapper}>
            <Box style={videoBackground} sx={{ background: 'black' }}>
                <video style={videoStyle} autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                </video>
            </Box>
            <NavBar />
            <Box sx={{ color: 'white', width: '80%', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant='h4' sx={{ fontWeight: 'bold', marginBottom: '25px' }}>DISCOVER THE NIGHTLIFE, ONE EVENT AT A TIME</Typography>
                <Typography sx={textWrapper} textAlign={"center"} variant='h6'>A world where people can effortlessly access comprehensive event information, connect with their favorite venues and artists.</Typography>
            </Box>
        </Box>
    )
}

export default LandingPage