import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import axios from 'axios';
import PopupSpecialEvents from './PopupSpecialEvents';

import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import HoverCard from "../components/HoverCard";


const SpecialEvents = () => {
    const [swiper, setSwiper] = useState(null);

    const isMobile = () => {
        return window.innerWidth <= 768;
    };
    const goToPrevSlide = () => {
        if (swiper) {
        swiper.slidePrev();
        }
    };

    const goToNextSlide = () => {
        if (swiper) {
        swiper.slideNext();
        }
    };

    const [eventData, setEventData] = useState(null);
    const [error, setError] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const [cardsPerPage, setCardsPerPage] = useState(4);

    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const [showArrows, setShowArrows] = useState(true);
    const handleSlideChange = (swiper) => {
        setIsStart(swiper.isBeginning)
        setIsEnd(swiper.isEnd)
    };
    
    useEffect(() => {
        if (swiper && swiper.slides) {
            const slideCount = swiper.slides.length;
            if(isMobile()){
                setShowArrows(slideCount > 2)
            }else{
                setShowArrows(slideCount > 4)
            }
        }
    }, [swiper,eventData]);

    useEffect(() => {
        updateCardsPerPage();
        window.addEventListener('resize', updateCardsPerPage);

        return () => {
            window.removeEventListener('resize', updateCardsPerPage);
        };
    }, []);

    const startIndex = currentPage * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const currentCards = eventData?.slice(startIndex, endIndex);

    const updateCardsPerPage = () => {
        if (window.innerWidth < 768) {
            setCardsPerPage(2);
        } else {
            setCardsPerPage(4);
        }
    };

    useEffect(() => {
        updateCardsPerPage();
        window.addEventListener('resize', updateCardsPerPage);

        return () => {
            window.removeEventListener('resize', updateCardsPerPage);
        };
    }, []);


    useEffect(() => {
        updateCardsPerPage();
        window.addEventListener('resize', updateCardsPerPage);

        return () => {
            window.removeEventListener('resize', updateCardsPerPage);
        };
    }, []);

    useEffect(() => {
        const eventsUrl = 'https://night-out-d92dbe5b8513.herokuapp.com/api/special/event';
        axios.get(eventsUrl)
            .then(response => {
                setEventData(response.data);
            })
            .catch(error => {
                console.log('Error fetching data:', error);
                setError(error)
            });
    }, []);




    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleClickOpen = (event) => {
        setSelectedEvent(event);
    };

    const handleClose = () => {
        setSelectedEvent(null);
    };


    if (error) {
        return <div>Error: {error}</div>;
    }



    return (
        <>

            {eventData && eventData.length && (
                <Box padding={cardsPerPage === 2 ? '20px' : '48px'} sx={{ background: 'black' }}>
                    <Typography variant='h3' sx={{ fontSize: cardsPerPage === 2 && '2rem !important', width: cardsPerPage === 2 ? '70%' : '50%', margin: '0 auto', textAlign: 'center', color: 'white' }}>Special Events</Typography>
                    <Box sx={{ marginTop: '48px', display: 'flex', justifyContent: 'center', gap: cardsPerPage === 2 ? '15px' : '25px' }}>
                    <Swiper onSlideChange={handleSlideChange} className={"swiper-container_custom"} onSwiper={setSwiper} ref={swiper} slidesPerView={1} spaceBetween={"20"} freeMode={false}
                            breakpoints={{
                                320: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 30,
                                },
                              }}>
                        {eventData && eventData?.map((eventData, index) => (
                            <SwiperSlide key={index}>
                                <HoverCard event={eventData} />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    </Box>
                    {showArrows &&
                        <Box sx={{ marginTop: '16px' ,textAlign: 'center',}} >
                            <IconButton onClick={goToPrevSlide} disabled={isStart}>
                                <ArrowCircleLeftIcon
                                    sx={{ fontSize: '2.5rem', color: isStart ? 'grey' : 'white' }}
                                />
                            </IconButton>
                            <IconButton onClick={goToNextSlide} disabled={isEnd}>
                                <ArrowCircleRightIcon
                                    sx={{ fontSize: '2.5rem', color: isEnd ? 'grey' : 'white' }}
                                />
                            </IconButton>
                        </Box>
                    }

                    {selectedEvent && (
                        <PopupSpecialEvents open={true} handleClose={handleClose} event={selectedEvent} cardsPerPage={cardsPerPage} />
                    )}
                </Box>)}
        </>
    )
}

export default SpecialEvents